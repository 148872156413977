import React from "react";

import { DataTable, DateAndTime, PositionHead, TableRow } from "../../helper/table";

import { formatAmount, formatAmountInDollar, limitDecimals, parseValue } from "lib/numbers";

import icView from "img/ic-view.svg";
import { BigNumber } from "ethers";
import { useThemeContext } from "contexts/ThemeProvider";
import { formatOrder } from "../../helper";

const ORDER_TYPE = {
  LIMIT_ORDER: "Limit Order",
  STOP_LIMIT: "Stop Limit",
  STOP_LOSS: "Stop Loss",
  TAKE_PROFIT: "Take Profit",
  TRAILING_STOP: "Trailing Stop",
};

// const renderPosSize = (position) => {
//   let result = { size: 0, quantity: 0 };
//   if (position.type === "TRIGGER") {
//     const size = parseValue(position.size, 30);
//     const averagePrice = parseValue(position.averagePrice, 30);

//     const posQty = size.gt(0) ? size.mul(parseValue(1, 30)).div(averagePrice) : 0;
//     result = {
//       size: formatAmount(size, 30, 2, true),
//       quantity: formatAmount(posQty, 30, 4, true),
//     };
//   } else if (position.type === "PENDDING") {
//     const posQty = BigNumber.from(position.pendingSize)
//       .mul(parseValue(1, 30))
//       .div(BigNumber.from(position.positionType === "Limit Order" ? position?.lmtPrice : position?.stpPrice));
//     result = {
//       size: formatAmount(position.pendingSize, 30, 2, true),
//       quantity: formatAmount(posQty, 30, 4, true),
//     };
//   } else {
//     const size = BigNumber.from(position.size);
//     const averagePrice = BigNumber.from(position.averagePrice);
//     const posQty = size.mul(parseValue(1, 30)).div(averagePrice);
//     result = {
//       size: formatAmount(size, 30, 2, true),
//       quantity: formatAmount(posQty, 30, 4, true),
//     };
//   }
//   return result;
// };

// const renderCollateral = (position) => {
//   const collateral = parseValue(position.collateral, 30);
//   if (position.type === "TRIGGER") {
//     return formatAmountInDollar(collateral, 30, 2, true);
//   } else if (position.type === "PENDDING") return formatAmountInDollar(position.pendingCollateral, 30, 2, true);
//   else return formatAmountInDollar(BigNumber.from(position.collateral), 30, 2, true);
// };

const renderParamaters = (position) => {
  let result = { param1: 0, param1Sub: "0", param2: null, param2Sub: "%" };

  if (position.type === "TRIGGER") {
    const conditionQuantity = Number(position.trigger.amountPercent / 1000);
    result = {
      ...result,
      param1: `${formatAmount(position?.trigger?.price, 30, 2, true)}`,
      param1Sub: position.trigger.isTP ? "TP" : "SL",
      param2: conditionQuantity?.toString().includes(".") ? limitDecimals(conditionQuantity, 2) : conditionQuantity,
    };
  } else if (position.type === "PENDDING") {
    result = {
      ...result,
      param1: formatAmount(
        position.positionType === "Limit Order" ? position?.lmtPrice : position?.stpPrice,
        30,
        2,
        true
      ),
      param1Sub: position.positionType === "Limit Order" ? "LP" : "SP",
      param2: position.positionType === "Stop Limit" ? formatAmount(position?.lmtPrice, 30, 2, true) : null,
      param2Sub: position.positionType === "Stop Limit" ? "LP" : null,
    };
  } else {
    const size = BigNumber.from(position.size);
    const sizeDelta = BigNumber.from(position.sizeDelta);
    const amountPercent = sizeDelta.mul(parseValue(1, 30)).div(size);
    result = {
      ...result,
      param1:
        position.stepType == 0
          ? formatAmount(BigNumber.from(position.stepAmount), 30, 2, true)
          : formatAmount(BigNumber.from(position.stepAmount), 3, 2, true),
      param1Sub: position.stepType == 1 ? "T%" : "TA",
      param2: size.eq(sizeDelta) ? 100 : formatAmount(amountPercent, 30, 2, true),
    };
  }
  return result;
};

const Orders = ({ orders, chainId, assets, marketTokensInfo, isEmpty }) => {
  const { lightThemeClassName } = useThemeContext();

  const formattedOrders = orders.map((order) => {
    return formatOrder(order, assets, marketTokensInfo);
  });

  return (
    <DataTable $noIndex $autoHeight $noHeading={isEmpty} className={lightThemeClassName}>
      <div className="head">
        <div className="col-name">Position</div>
        {/* <div className="col-name">Created</div> */}
        <div className="col-name">Type</div>
        <div className="col-name">Order</div>
        <div className="col-name">Trigger Conditions</div>
        <div className="col-name">Pos. Size</div>
        <div className="col-name">Mark. Price</div>
        {/* <div className="col-name">Collateral</div> */}
        {/* <div className="col-name">Parameters</div> */}
      </div>
      <div className="body">
        {formattedOrders.map((order, i) => {
          const asset = order.isV2 ? assets[order.indexToken.id] || {} : undefined;
          const size = parseValue(order.size, 30);
          const markPrice = parseValue(order.marketPrice, 30);

          const compareCondition = order.isV2
            ? order?.trigger?.isTP
              ? order.isLong
                ? "≥"
                : "≤"
              : !order.isLong
              ? "≥"
              : "≤"
            : order.triggerAboveThreshold
            ? "≥"
            : "≤";

          const conditionPrice = `${formatAmount(
            parseValue(order?.trigger?.price || order?.triggerPrice, 30),
            30,
            asset?.decimals || 2,
            true
          )}`;

          let triggerConditionText = `Mark Price ${compareCondition} $${conditionPrice}`;

          if (order.orderType === "TRAILING_STOP") {
            triggerConditionText =
              order.stepType == 0
                ? "Trailing Amount = $" + formatAmount(BigNumber.from(order.stepAmount), 30, 2, true)
                : "Trailing Percentage = " + formatAmount(BigNumber.from(order.stepAmount), 3, 2, true) + "%";
            // {or.stepType == 0
            //   ? "Trailing Amount = $" + formatAmount(BigNumber.from(position.stepAmount), 30, 2, true)
            //   : "Trailing Percentage = " + formatAmount(BigNumber.from(position.stepAmount), 3, 2, true) + "%"}
          }

          let quantity;

          if (order?.averagePrice && order.averagePrice != 0) {
            const averagePrice = parseValue(order.averagePrice, 30);
            quantity = size.mul(parseValue(1, 30)).div(averagePrice);
          } else {
            const limitPrice = parseValue(order?.lmtPrice || order?.triggerPrice, 30);
            quantity = size.mul(parseValue(1, 30)).div(limitPrice);
          }

          let orderText;

          if (order.isV2) {
            switch (order.orderType) {
              case "STOP_LOSS":
              case "TAKE_PROFIT":
                const sizeAfterPercent = size.mul(parseValue(Number(order?.trigger?.amountPercent), 0));
                orderText = `Decrease ${formatAmountInDollar(sizeAfterPercent, 35, 2, true)}`;
                break;

              case "TRAILING_STOP":
                orderText = `Decrease ${formatAmountInDollar(parseValue(order.sizeDelta, 30), 30, 2, true)}`;
                break;
              default:
                orderText = `Increase position`;
                break;
            }
          } else {
            switch (order.orderType) {
              case "STOP_LOSS":
              case "TAKE_PROFIT":
                orderText = `Decrease ${formatAmountInDollar(size, 30, 2, true)}`;
                break;

              default:
                orderText = `Increase position`;
                break;
            }
          }

          const posId = order.isV2 ? order.posId : order.positionId;

          return (
            <TableRow key={order.key || i} to={posId ? `/analytic/position/${posId}` : undefined}>
              <div d-label="Position" className="col-body">
                <PositionHead
                  position={order}
                  isV2={order.isV2}
                  asset={asset}
                  noFlag
                  id={order.isV2 && posId}
                  hideId={!order.isV2 && order.orderType === "LIMIT_ORDER"}
                />
              </div>
              {/* <div d-label="Created" className="col-body">
                <div className="col-body-content">
                  <DateAndTime unix={order.timestamp} />
                </div>
              </div> */}
              <div d-label="Type" className="col-body">
                {ORDER_TYPE[order.orderType]}
              </div>
              <div d-label="Order" className="col-body">
                {orderText}
              </div>
              <div d-label="Trigger Conditions" className="col-body">
                <div className="col-body-content">{triggerConditionText}</div>
              </div>
              <div d-label="Pos. Size" className="col-body">
                <div className="col-body-content">
                  <div>{formatAmountInDollar(size, 30, 2, true)}</div>
                  {quantity && (
                    <div className="muted fz-12">
                      {formatAmount(quantity, 30)} {asset?.tokenSymbol || order.indexToken.symbol}
                    </div>
                  )}
                </div>
              </div>
              <div d-label="Mark. Price" className="col-body">
                <div className="col-body-content">
                  <div>{formatAmountInDollar(markPrice, 30, asset?.decimals || 2, true)}</div>
                </div>
              </div>
              {/* <div d-label="Collateral" className="col-body">
                {renderCollateral(order)}
                {order.collateral && formatAmountInDollar(parseValue(order.collateral, 30), 30, 2, true)}
              </div> */}
              {/* <div d-label="Parameters" className="col-body">
                <div className="col-body-content">
                  <div>
                    {renderParamaters(order).param1} <span className="muted">{renderParamaters(order).param1Sub}</span>
                  </div>
                  {renderParamaters(order).param2 && (
                    <div className="muted fz-12">
                      {renderParamaters(order).param2} <span>{renderParamaters(order).param2Sub}</span>
                    </div>
                  )}
                </div>
              </div> */}

              <img className="icon-view" src={icView} alt="view_ic" />
            </TableRow>
          );
        })}
        {/* {isLoading && <div className="skeleton-box" style={{ height: "30px", marginTop: "20px" }} />} */}
      </div>
    </DataTable>
  );
};

export default Orders;
