import axios from "axios";
import { useChainId } from "lib/chains";
import { useEffect, useState } from "react";
import { getServerBaseUrl, getServerUrl } from "config/backend";
import { FANTOM, SUPPORTED_V2_CHAINS } from "config/chains";
import { hashString } from "lib/legacy";

type Params = {
  pageSize: number;
};

export const DEFAULT_PAGE_SIZE_CLOSED = 10;

const useUserClosedPositionsV1 = (account: string | undefined | null, params?: Params) => {
  const { pageSize = DEFAULT_PAGE_SIZE_CLOSED } = params || {};
  const [positions, setPositions] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const { chainId } = useChainId();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (account && SUPPORTED_V2_CHAINS.includes(chainId)) {
      const fetchPositions = async () => {
        const { data: responseData } = await axios.get(
          `https://api.yummy.fi/stats/bartio/trader/${account.toLowerCase()}/closed-positions`,
          {
            params: {
              page,
              pageSize,
              type: "V1",
            },
          }
        );

        if (responseData?.data) {
          const { rows = [], count } = responseData?.data;
          setPositions(
            rows
              .filter((x) => x.type === "V1")
              .map((item) => {
                const stringId = `${item.openTime.toString()}-${item.id}-${item.openTime.toString()}`;
                const posIdHash = hashString(stringId);
                return {
                  ...item,
                  posId: posIdHash,
                };
              })
          );
          setCount(count);
        }
        setIsLoading(false);
      };

      fetchPositions();

      const interval = setInterval(async () => {
        fetchPositions();
      }, 10000);
      return () => clearInterval(interval);
    } else {
      setIsLoading(false);
    }
  }, [account, chainId, page, pageSize]);

  return { positions, count, currentPage: page, setCurrenPage: setPage, isLoading };
};
export default useUserClosedPositionsV1;
