import { Select } from "antd";
import styled from "styled-components";
import downIc from "img/downTriangleIc.svg";
import Tooltip from "components/Tooltip/Tooltip";
import "../styles/AnalyticsV2.styled";
import cx from "classnames";

import { useThemeContext } from "contexts/ThemeProvider";
import { useMediaQuery } from "react-responsive";
import top1 from "img/top1.svg";
import top2 from "img/top2.svg";
import top3 from "img/top3.svg";
import top2Light from "img/top2Light.svg";
import top3Light from "img/top3Light.svg";
import icView from "img/ic-view.svg";

import { formatAmount, formatAmountInDollar, parseValue } from "lib/numbers";
import moment from "moment";
import { DataTable, PositionHead, SkeletonLoadingTable, TableRow, renderArrows } from "../helper/table";
import useMarketAssets from "hooks/useMarketAssets";
import NoItemFound from "pages/Analytics/components/NoItemFound";
import PaginationV2 from "../../AnalyticsV3/components/PaginationV2";
import { useState } from "react";
import useMergedTopPositions, { DEFAULT_SORT } from "../../../hooks/useMergedTopPositions";
import { hashString } from "../../../lib/legacy";

const topsImg = [top1, top2, top3];
const topsImgLight = [top1, top2Light, top3Light];

const SIZE_DESC = "size DESC";
const SIZE_ASC = "size ASC";
const NET_PNL_DESC = "pnlAfterFee DESC";
const NET_PNL_ASC = "pnlAfterFee ASC";
const NET_VALUE_DESC = "pnlValueAfterFee DESC";
const NET_VALUE_ASC = "pnlValueAfterFee ASC";

const ORDER_BY_OPTIONS = [
  {
    value: SIZE_DESC,
    label: "Highest to lowest in Size",
  },
  {
    value: SIZE_ASC,
    label: "Lowest to highest in Size",
  },
  {
    value: NET_PNL_DESC,
    label: "Highest to lowest in PnL",
  },
  {
    value: NET_PNL_ASC,
    label: "Lowest to highest in PnL",
  },
  {
    value: NET_VALUE_DESC,
    label: "Highest to lowest in Net Value",
  },
  {
    value: NET_VALUE_ASC,
    label: "Lowest to highest in Net Value",
  },
];

const MAPPED_BY_KEY = {
  size: "Size",
  pnlValueAfterFee: "Net Value",
  pnlAfterFee: "PnL",
};

const TopPositionTab = ({ chainId, isValidChain }) => {
  const assets = useMarketAssets();
  const isSmallDevice = useMediaQuery({ query: "(max-width: 1023px)" });
  // const [from, setFrom] = useState();
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const { sort, handleSort, isLoading, data, currentPage, setCurrentPage, rowsPerPage, setRowsPerPage } =
    useMergedTopPositions(chainId);

  // const FILTER_BY_OPTIONS = useMemo(() => {
  //   return [
  //     {
  //       label: "All time",
  //       value: configContest?.[chainId]?.START_TIMESTAMP,
  //     },
  //     {
  //       label: "24 Hours",
  //       value: moment().unix() - SECONDS_PER_DAY,
  //     },
  //     {
  //       label: "7 Days",
  //       value: moment().unix() - SECONDS_PER_DAY * 7,
  //     },
  //     {
  //       label: "1 Month",
  //       value: moment().unix() - SECONDS_PER_DAY * 30,
  //     },
  //   ];
  // }, []);

  function getRank(index) {
    if (sort.type === "ASC") {
      const total = data.count;
      return total - index - (currentPage - 1) * rowsPerPage;
    }
    return currentPage * rowsPerPage - rowsPerPage + index + 1;
  }

  const isEmpty = !isLoading && data?.rows?.length === 0;
  return (
    <Wrapper className={lightThemeClassName}>
      {isValidChain && (
        <>
          <div className="top-filter">
            {/* <StyledSelect1
              showArrow
              suffixIcon={<img className="downIc" src={downIc} alt="" />}
              onChange={(value) => {
                setFrom(value);
              }}
              defaultValue={FILTER_BY_OPTIONS[0]}
              bordered={false}
              options={FILTER_BY_OPTIONS}
              popupClassName={lightThemeClassName}
              className={lightThemeClassName}
            /> */}
            <div className="last-update">
              Last update: {moment(data.lastUpdateTime ? data.lastUpdateTime * 1000 : Date.now()).fromNow()}
            </div>
          </div>
          {isSmallDevice && (
            <div className="filter-block">
              Ordered by:{" "}
              <StyledSelect
                showArrow
                suffixIcon={<img className="downIc" src={downIc} alt="" />}
                onChange={(value) => {
                  const [field, type] = value.split(" ");
                  handleSort(field, type);
                }}
                defaultValue={ORDER_BY_OPTIONS.find((opt) => opt.value === DEFAULT_SORT)}
                bordered={false}
                options={ORDER_BY_OPTIONS}
                popupClassName={lightThemeClassName}
              />
            </div>
          )}
          {isEmpty ? (
            <NoItemFound />
          ) : (
            <DataTable
              $autoHeight
              $rowHeightSkeleton="63.19px"
              $rowHeightSkeletonMobile="194.77px"
              className={`analytics-table ${lightThemeClassName}`}
            >
              <div className="head">
                <div className="col-name">
                  <Tooltip
                    handle={`Top`}
                    position="center-top"
                    className="fit-content"
                    renderContent={() => {
                      return <div>Sort by {MAPPED_BY_KEY[sort.field]}</div>;
                    }}
                  />
                </div>
                {/* <div
                  className="col-name"
                  style={{
                    maxWidth: 80,
                  }}
                >
                  PosID
                </div> */}
                <div
                  className="col-name"
                  style={{
                    minWidth: 400,
                  }}
                >
                  Position
                </div>
                <div className="col-name" onClick={() => handleSort("size")}>
                  Size
                  {renderArrows("size", sort, isLightTheme)}
                </div>
                <div className="col-name" onClick={() => handleSort("pnlValueAfterFee")}>
                  Net Value
                  {renderArrows("pnlValueAfterFee", sort, isLightTheme)}
                </div>
                <div className="col-name">Collateral</div>
                <div className="col-name" onClick={() => handleSort("pnlAfterFee")}>
                  <Tooltip
                    position="center-top"
                    handle={<>PnL</>}
                    renderContent={() => "Unrealized PnL after accrued funding & borrow fees"}
                  />
                  {renderArrows("pnlAfterFee", sort, isLightTheme)}
                </div>
              </div>
              <div className="body">
                {!isLoading &&
                  data?.rows?.map((row, i) => {
                    const rank = getRank(i);
                    const isV2 = row.type === "V2";

                    const size = parseValue(row.size, 30);
                    const pnlAfterFee = parseValue(row.pnlAfterFee, 30);
                    const collateral = parseValue(row.collateral, 30);
                    const netValue = parseValue(row.pnlValueAfterFee, 30);

                    const roi = pnlAfterFee.mul(parseValue(100, 30)).div(collateral);
                    const prefixRoi = roi?.gt(0) ? "+" : roi?.lt(0) ? "-" : "";

                    const { latestPrice, ...asset } = isV2
                      ? assets?.[row.indexToken.id] || {}
                      : { latestPrice: row.marketPrice };

                    const averagePrice = parseValue(row.averagePrice, 30);
                    const marketPrice = parseValue(latestPrice, 30);

                    const stringId =
                      row.openTimestamp && `${row.openTimestamp.toString()}-${row.id}-${row.openTimestamp.toString()}`;
                    const posIdHash = isV2 ? undefined : hashString(stringId);

                    return (
                      <TableRow
                        key={i}
                        noScaleOnHover
                        // to={`/analytic/position/${row.id}`}
                      >
                        <div d-label="Top" className={`col-body top-${rank}`}>
                          {!isLightTheme && (
                            <>{topsImg[rank - 1] ? <img src={topsImg[rank - 1]} alt={`top-${rank}`} /> : rank}</>
                          )}
                          {isLightTheme && (
                            <>
                              {topsImgLight[rank - 1] ? <img src={topsImgLight[rank - 1]} alt={`top-${rank}`} /> : rank}
                            </>
                          )}
                        </div>

                        {/* <div
                          d-label="PosID"
                          className="col-body gray"
                          style={{
                            maxWidth: 80,
                          }}
                        >
                          {isV2 && `#${row.id}`}
                        </div> */}
                        <div
                          d-label="Position"
                          className="col-body order-top-mobile col-min-width"
                          style={{
                            minWidth: 400,
                          }}
                        >
                          <PositionHead
                            position={row}
                            asset={asset}
                            averagePrice={averagePrice}
                            marketPrice={marketPrice}
                            hasPrice
                            noFlag
                            isV2={isV2}
                            id={posIdHash}
                          />
                        </div>
                        <div d-label="Entry Price" className="col-body only-mobile">
                          {formatAmountInDollar(averagePrice, 30, asset.decimals || 2, true, {
                            autoDisplayDecimals: true,
                          })}
                        </div>
                        <div d-label="Mark Price" className="col-body only-mobile">
                          {formatAmountInDollar(marketPrice, 30, asset.decimals || 2, true, {
                            autoDisplayDecimals: true,
                          })}
                        </div>
                        <div d-label="Size" className="col-body">
                          {formatAmountInDollar(size, 30, 2, true)}
                        </div>
                        <div d-label="Net Value" className="col-body">
                          {formatAmountInDollar(netValue, 30, 2, true)}
                        </div>
                        <div d-label="Collateral" className={`col-body`}>
                          {formatAmountInDollar(collateral, 30, 2, true)}
                        </div>
                        <div d-label="PnL" className={cx("col-body")}>
                          <div className="col-body-content">
                            {formatAmountInDollar(pnlAfterFee, 30, 2, true)}
                            <div className={cx("fz-12", { positive: prefixRoi === "+", negative: prefixRoi === "-" })}>
                              ({prefixRoi}
                              {formatAmount(roi, 30, 2, true).replace("-", "")}%)
                            </div>
                          </div>
                        </div>
                        <img className="icon-view" src={icView} alt="view_ic" />
                      </TableRow>
                    );
                  })}
              </div>

              {isLoading && <SkeletonLoadingTable noHeading rows={10} />}
              {!isEmpty && (
                <PaginationV2
                  className="pagination-analytics"
                  currentPage={currentPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  setCurrentPage={setCurrentPage}
                  rows={data?.count}
                />
              )}
            </DataTable>
          )}
        </>
      )}
      {!isValidChain && <NoItemFound />}
    </Wrapper>
  );
};

const StyledSelect1 = styled(Select)`
  &:not(.ant-select-customize-input) .ant-select-selector {
    width: fit-content;
    display: flex;
    height: 40px !important;
    padding: 0px var(--Base-Unit-S-12, 12px) !important;
    justify-content: center;
    align-items: center;
    gap: var(--Base-Unit-XS-8, 8px);
    border-radius: 12px;
    border: 1px solid var(--Border, rgba(255, 255, 255, 0.1)) !important;
    background-color: var(--Nature-1, #191919) !important;
  }

  .ant-select-arrow {
    rotate: 180deg;
  }
`;

const StyledSelect = styled(StyledSelect1)`
  &:not(.ant-select-customize-input) .ant-select-selector {
    height: 44px !important;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;

  .col-body-content {
    div {
      margin-top: 4px;
    }
  }

  .top-filter {
    position: absolute;
    bottom: calc(100% + 24px);
    right: 0;
    z-index: 20;
    margin: 0 auto;
    gap: 24px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    transform: translateY(-50%);
    @media screen and (max-width: 1023px) {
      position: static;
      transform: none;
    }
    @media screen and (max-width: 700px) {
      margin-top: -0px;
      margin-bottom: 24px;
    }

    .last-update {
      color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
      font-size: 14px;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
    }
  }

  .analytics-table {
    padding: 24px;
    border-radius: 16px;
    background: var(--Nature-1, #18191a);

    @media screen and (max-width: 767px) {
      padding: 0;
      background: transparent;

      .position-head-prices {
        display: none !important;
      }
    }
  }

  .pagination-analytics {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .ant-select {
      & > .ant-select-selector {
        border-radius: 4px;
      }
    }

    .btn.btn-primary {
      border-radius: var(--Border_radius, 4px);
      border: 1px solid var(--Border, rgba(255, 255, 255, 0.1));
      width: 24px;
      height: 24px;
    }

    .btn-label {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .top-filter {
      align-self: flex-start;
      margin: 0;
      margin-bottom: 16px;
    }

    .filter-block {
      margin-bottom: 16px;
      order: -1;
    }
  }

  @media screen and (max-width: 767px) {
    .top-filter {
      margin-bottom: 0;
    }
    margin-top: 24px;

    .filter-block {
      color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
      display: flex;
      /* flex-direction: column; */
      gap: 8px;
      align-items: center;

      .ant-select-selector {
        padding-left: 8px;
      }
    }
  }

  &.theme--light {
    .top-filter {
      .last-update {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
      }
    }
  }
`;

export default TopPositionTab;
