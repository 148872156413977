import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import cx from "classnames";

import UpArrowIcon from "components/Icon/AnalyticIcon/upArrowIc";
import { importImage } from "lib/legacy";
import { formatAmount, formatAmountInDollar, parseValue } from "lib/numbers";
import moment from "moment";
import { dateFormat, timeFormat } from "lib/dates";
import { BigNumber } from "ethers";
import ExternalLink from "components/ExternalLink/ExternalLink";

export const renderArrows = (field, sort, isLightTheme = false) => {
  return (
    <div className="group-arrow">
      <span>
        {sort.field === field && sort.type === "ASC" ? (
          <UpArrowIcon fill={isLightTheme ? "#0D1A16" : "#fff"} className="up" />
        ) : (
          <UpArrowIcon fill={isLightTheme ? "#0d1a1633" : "#ADABAB"} className="up" />
        )}
      </span>

      <span>
        {sort.field === field && sort.type === "DESC" ? (
          <UpArrowIcon fill={isLightTheme ? "#0D1A16" : "#fff"} className="down" />
        ) : (
          <UpArrowIcon fill={isLightTheme ? "#0d1a1633" : "#ADABAB"} className="down" />
        )}
      </span>
    </div>
  );
};

export const TableRow = ({ children, to, href, noScaleOnHover, className = "" }) => {
  const classes = cx(["body-row", className], {
    disabledScale: noScaleOnHover,
  });
  if (to) {
    return (
      <Link className={classes} to={to}>
        {children}
      </Link>
    );
  }

  if (href) {
    return (
      <ExternalLink className={classes} href={href}>
        {children}
      </ExternalLink>
    );
  }

  return <div className={classes}>{children}</div>;
};

export const PositionHead = ({
  position,
  noFlag = false,
  hasPrice = false,
  hideLeverage = false,
  asset,
  averagePrice,
  marketPrice,
  isV2,
  hideId,
  id: alterId,
}) => {
  const {
    id,
    isLong,
    leverage: rawLeverage,
    pendingLeverage,
    indexToken: { symbol },
    posIdHash,
  } = {
    indexToken: {},
    ...position,
  };
  const { tokenSymbol: defaultTokenSymbol, symbol: defaultSymbol, decimals } = asset || {};

  const leverage = hideLeverage
    ? undefined
    : (BigNumber.isBigNumber(rawLeverage) ? rawLeverage : parseValue(rawLeverage, 30)) || pendingLeverage;

  const _symbol = isV2 ? defaultTokenSymbol : symbol;

  const tokenImage = _symbol ? importImage(`ic_${_symbol.toLowerCase()}_24.svg`) : undefined;

  const longOrShortText = isLong ? "LONG" : "SHORT";

  const _id = alterId || (isV2 ? id : posIdHash);

  return (
    <div
      className={cx("position-head", {
        hasPrice,
      })}
    >
      {!noFlag && (
        <div
          className={cx("flag", {
            isLong,
          })}
        />
      )}
      {tokenImage ? <img src={tokenImage} alt="tokenImage" /> : <div className="skeleton-box token-img" />}
      <div className="position-head-detail">
        <div className="position-head-detail-token">
          <div className="position-head-symbol-posId">
            <span className="position-head-symbol-symbol">{symbol || defaultSymbol}</span> {!hideId && _id && `#${_id}`}
          </div>
          <div className="position-head-leverage">
            <span
              className={cx("longOrShort", {
                long: isLong,
                short: !isLong,
              })}
            >
              {longOrShortText}
            </span>
            {leverage && (
              <span className="leverage">
                {BigNumber.isBigNumber(leverage) ? formatAmount(leverage, 30, 2, true) : Number(leverage).toFixed(2)}X
              </span>
            )}
          </div>
        </div>
        {hasPrice && (
          <div className="position-head-prices">
            <div className="position-head-price">
              Entry Price:{" "}
              <span>
                {formatAmountInDollar(averagePrice, 30, decimals || 2, true, {
                  autoDisplayDecimals: true,
                })}
              </span>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="4" height="5" viewBox="0 0 4 5" fill="none">
              <circle cx="2" cy="2.5" r="2" fill="#919499" />
            </svg>
            <div className="position-head-price">
              Mark Price:{" "}
              <span>
                {formatAmountInDollar(marketPrice, 30, decimals || 2, true, {
                  autoDisplayDecimals: true,
                })}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const DateAndTime = ({ unix }) => {
  return (
    <>
      <div>{moment(new Date(unix * 1000)).format(dateFormat)}</div>
      <div className="muted fz-12">{moment(new Date(unix * 1000)).format(timeFormat)}</div>
    </>
  );
};

export const SkeletonLoadingTable = ({ noHeading, height = undefined, rows = 5 }) => {
  const styles = height && {
    height,
  };
  return (
    <div className="table-state-loading">
      {!noHeading && (
        <div className="table-state-loading-head">
          {Array(7)
            .fill(null)
            .map((_, i) => (
              <div key={i} className="skeleton-box" />
            ))}
        </div>
      )}
      <div className="table-state-loading-body">
        {Array(rows)
          .fill(null)
          .map((_, i) => (
            <div key={i} className="skeleton-box" style={styles} />
          ))}
      </div>
    </div>
  );
};

export const DataTable = styled.div`
  .Tooltip-popup {
    width: fit-content !important;
    min-width: unset !important;
    white-space: nowrap !important;
  }
  .head {
    display: flex;
    padding: 0px 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    /* margin-bottom: 8px; */

    .col-name {
      color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
      font-size: 12px;
      font-weight: 400;
      line-height: 140%; /* 16.8px */
      flex: 1;

      .Tooltip-handle {
        color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
      }

      &:first-child {
        min-width: 50px;
        flex: unset;
      }

      &:has(.group-arrow) {
        display: flex;
        align-items: center;
        gap: 8px;
        user-select: none;
        cursor: pointer;

        .group-arrow {
          display: flex;
          flex-direction: column;
          gap: 2px;

          svg {
            display: flex;

            &.down {
              rotate: 180deg;
            }
          }
        }
      }
    }
  }

  .body {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Base-Unit-XS-8, 8px);

    .body-row {
      display: flex;
      height: 56px;
      padding: 12px 16px;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      border-radius: 12px;
      background: var(--Nature-1, #212224);
      position: relative;
      transition: 200ms;
      text-decoration: none;
      border: 1px solid transparent;

      &:not(.disabledScale) {
        cursor: pointer;
        &:hover {
          scale: 1.02;
          z-index: 10;
        }
      }

      &:has(.ribbon) {
        overflow: hidden;
      }

      .ribbon {
        position: fixed;
        background: #72cc37;
        box-shadow: 0 0 0 999px #72cc37;
        color: #000;
        font-size: 8px;
        font-weight: bold;
        padding: 2px 0;
        clip-path: inset(0 -100%);
        inset: 0 auto auto 0;
        transform-origin: 100% 0;
        transform: translate(-29.3%) rotate(-45deg);
        position: absolute;
        left: -1.6869999999999994px;
        top: -0.3650000000000002px;
        z-index: 999;
      }
      .ribbon-2 {
        background: #72cc37;
        box-shadow: 0 0 0 999px #72cc37;
      }
      .ribbon-3 {
        background: #72cc37;
        box-shadow: 0 0 0 999px #72cc37;
      }
      .ribbon-4 {
        background: #72cc37;
        box-shadow: 0 0 0 999px #72cc37;
      }
      .ribbon-5 {
        background: #72cc37;
        box-shadow: 0 0 0 999px #72cc37;
      }
      img[alt="view_ic"],
      .icon-last {
        position: absolute;
        right: 16px;
      }

      .col-link:hover {
        path {
          fill: #176ce5;
        }
      }

      .col-body {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        flex: 1;

        &.only-mobile {
          @media screen and (min-width: 768px) {
            display: none;
          }
        }

        &.gray {
          color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
        }

        .risk {
          span {
            color: #f6475d;
          }

          &.low {
            span {
              color: #7ee55c;
            }
          }

          &.medium {
            span {
              color: #ffb200;
            }
          }
        }

        .muted.fz-12 {
          font-weight: 400;
        }

        .top {
          display: inline-flex;
          padding: 2px var(--Border_radius, 4px);
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: var(--Base-Unit-XS-8, 8px);
          border-radius: var(--Border_radius, 4px);
          background: var(--HighLight, rgba(255, 255, 255, 0.1));
          color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
          font-size: 12px;
          font-weight: 700;
          line-height: 140%; /* 16.8px */
          margin-left: 8px;
        }

        &:first-child {
          min-width: 50px;
          flex: unset;
          padding-left: 12px;

          &.top-1 {
            position: relative;
            padding: 0;
            inset: 0;
          }

          &.top-2 {
            position: relative;
            padding: 0;
            inset: 0;
          }

          &.top-3 {
            position: relative;
            padding: 0;
            inset: 0;
          }

          /* @media screen and (min-width: 76px) {
            padding-left: 10px;
            font-size: 10px;
            font-weight: 700;
          } */
        }

        &.user {
          display: flex;
          align-items: center;
          gap: var(--Base-Unit-XS-8, 8px);
          flex: 1 0 0;
        }
      }
    }
  }

  .position-head {
    display: flex;
    align-items: center;
    gap: var(--Base-Unit-XS-8, 8px);

    .skeleton-box.token-img {
      width: 24px;
      height: 24px;
      border-radius: 100px;
    }

    img[alt="tokenImage"] {
      width: 24px;
      height: 24px;
    }

    .position-head-symbol-posId {
      color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));

      span {
        color: #0d1a16;
      }
    }

    .leverage {
      background: rgba(255, 199, 0, 0.2);
      color: var(--Leverage-Text, #d69e03);
    }

    .posId {
      color: var(--Text-Text_Secondary, #919499);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
    }

    .flag {
      width: var(--Border_radius, 4px);
      height: 37px;
      position: absolute;
      left: 0px;
      top: 14px;
      border-radius: 0px 24px 24px 0px;
      background: var(--Functional-Red, #f6475d);

      &.isLong {
        background: var(--Primary, #7ee55c);
      }
    }

    .position-head-detail {
      .position-head-detail-token {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: var(--Border_radius, 4px);
      }

      .position-head-symbol-posId {
        color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
        font-size: 14px;
        font-weight: 400;
        line-height: 140%; /* 19.6px */

        span {
          color: #fff;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
          line-height: 140%; /* 19.6px */
        }
      }

      .position-head-leverage {
        display: flex;
        align-items: center;
        gap: var(--Border_radius, 4px);

        .longOrShort {
          text-align: center;
          font-size: 12px;
          font-weight: 700;
          line-height: 140%; /* 16.8px */

          &.long {
            color: var(--Primary, #7ee55c);
          }

          &.short {
            color: var(--Functional-Red, #f6475d);
          }
        }

        .leverage {
          display: flex;
          padding: 0px var(--Border_radius, 4px);
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
          border-radius: var(--Border_radius, 4px);
          background: var(--Leverage-Background, rgba(255, 199, 0, 0.2));

          color: var(--Leverage-Text, #ffc700);
          font-size: 12px;
          font-weight: 700;
          line-height: 130%; /* 15.6px */
        }
      }
    }

    &.hasPrice {
      .position-head-detail-token {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }

      .position-head-prices {
        display: flex;
        align-items: center;
        gap: var(--Border_radius, 4px);

        svg {
          path {
            fill: #0d1a16;
          }
        }

        .position-head-price {
          color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
          font-size: 12px;
          font-weight: 400;
          line-height: 140%; /* 16.8px */
          display: flex;
          align-items: center;
          gap: 4px;

          span {
            color: #ffffff;
          }
        }
      }
    }
  }

  .table-state-loading {
    .table-state-loading-head {
      display: flex;
      padding: 0px 16px;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      margin-bottom: 8px;

      & > div.skeleton-box {
        height: 16.8px;
      }
    }

    .table-state-loading-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Base-Unit-XS-8, 8px);
      align-self: stretch;

      & > div.skeleton-box {
        height: 56px;
        border-radius: 12px;
      }
    }
  }

  ${({ $noIndex }) =>
    $noIndex
      ? css`
          .col-name:first-child,
          .col-body:first-child {
            min-width: unset !important;
            flex: 1 !important;
            padding-left: 0 !important;
          }
        `
      : css`
          .col-body {
            &:first-child {
              @media screen and (min-width: 1024px) {
                font-size: 14px !important;
                font-weight: 700 !important;
              }
            }
          }
        `}

  ${({ $noHeading }) =>
    $noHeading &&
    css`
      .head {
        display: none;
      }
    `}

  ${({ $autoHeight, $rowHeightSkeleton = "56px" }) =>
    $autoHeight &&
    css`
      .body-row {
        height: auto !important;
      }

      .table-state-loading {
        .table-state-loading-body {
          & > div.skeleton-box {
            height: ${$rowHeightSkeleton} !important;
          }
        }
      }
    `}

  ${({ $rowHeightSkeletonMobile }) =>
    $rowHeightSkeletonMobile &&
    css`
      @media screen and (max-width: 1023px) {
        .table-state-loading {
          .table-state-loading-body {
            & > div.skeleton-box {
              height: ${$rowHeightSkeletonMobile} !important;
            }
          }
        }
      }
    `}

  @media screen and (max-width: 1023px) {
    .head {
      display: none;
    }

    .body {
      .body-row {
        gap: 12px;
        flex-direction: column;
        align-items: stretch;
        height: unset !important;
        padding: 16px;

        img[alt="view_ic"],
        .icon-last {
          display: none;
        }

        &:has(.col-body .position-head) {
          padding: 0 0 16px 0;

          .col-body {
            max-width: unset !important;
            padding: 0 16px;
            align-items: center;

            &.gray {
              color: #fff;
            }

            &.order-top-mobile {
              order: -1;

              .position-head-detail-token {
                justify-content: flex-start;
              }
            }

            &:has(.position-head) {
              padding: 16px 16px 0 16px !important;
            }

            &:has(.col-body-content) {
              /* align-items: flex-start; */

              .col-body-content {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
              }
            }
          }
        }

        .col-body {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          &.col-min-width {
            min-width: unset !important;
          }

          &:first-child {
            padding: 0;
          }

          .position-head {
            width: 100%;

            .flag {
              display: none;
            }

            .position-head-detail {
              flex: 1;

              .position-head-detail-token {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
              }
            }
          }

          &:has(.position-head) {
            &::before {
              display: none;
            }
          }

          &.user {
            img {
              margin-left: auto;
              width: 16px;
              height: 16px;
            }
          }

          &::before {
            content: attr(d-label);
            color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
            font-size: 12px;
            font-weight: 400;
            line-height: 140%; /* 16.8px */
            margin-right: auto;
          }

          &.col-hide-mobile {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 16px;
  }

  &.theme--light {
    .head {
      .col-name {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;

        .Tooltip-handle {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
        }
      }
    }

    .body-row {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
      background: var(--Nature-1, #fff);
      /* height: 56px; */

      &::before {
        color: #0d1a16;
      }

      .col-body {
        color: #0d1a16 !important;

        &::before {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
        }

        .top {
          background: var(--HighLight, rgba(2, 178, 127, 0.15)) !important;
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
        }

        &.positive {
          color: #02b27f !important;
        }

        &.negative {
          color: #d62f44 !important;
        }
      }
    }

    .position-head {
      &.hasPrice {
        .position-head-detail-token {
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }

        .position-head-prices {
          display: flex;
          align-items: center;
          gap: var(--Border_radius, 4px);

          svg {
            path {
              fill: #0d1a16;
            }
          }

          .position-head-price {
            color: #0d1a16b2;

            span {
              color: #0d1a16;
            }
          }
        }
      }

      .position-head-symbol-posId {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));

        span {
          color: #0d1a16;
        }
      }

      .longOrShort {
        color: #d62f44 !important;

        &.long {
          color: #02b27f !important;
        }
      }

      .leverage {
        background: rgba(255, 199, 0, 0.2) !important;
        color: var(--Leverage-Text, #d69e03) !important;
      }
    }
  }
`;
