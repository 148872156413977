import { Trans } from "@lingui/macro";
import cx from "classnames";
import { FANTOM, SUPPORTED_CHAIN_ID_DEPOSIT, getConstant } from "config/chains";
import logoImg from "img/logo_GMY_v2.svg";
import logoImgV2Light from "img/logo_GMY_v2_light.svg";
import { useChainId } from "lib/chains";
import { useState } from "react";
import { FiX } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import "./Header.scss";
import { HeaderLink } from "./HeaderLink";

import perps from "img/perps.svg";
import swap_aggregator_ic from "img/swap_aggregator_ic.svg";

import arrow from "img/header/arrow.svg";
import arrowLight from "img/header/arrowLight.svg";
import perps_light from "img/header/perps_light.svg";
import swap_aggregator_ic_light from "img/header/swap_aggregator_ic_light.svg";
import exchangeIcon from "img/icons/ic-exchange.svg";

import { formatAmount } from "lib/numbers";

import { IconAnalytics, IconDashboard, IconEarn, IconFaucet, IconLogo, IconPerp, IconPor, IconSwap } from "assets";
import TransactionModal from "components/DepositModal";
import { useThemeContext } from "contexts/ThemeProvider";
import useProviderBalance from "hooks/useProviderBalance";
import IconArrow from "img/launch/iconArrow";

// type Props = {
//   small?: boolean;
//   clickCloseIcon?: () => void;
//   openSettings?: () => void;
//   redirectPopupTimestamp: number;
//   showRedirectModal: (to: string) => void;
//   account: string | null | undefined;
//   library: any;
// };

// const arrowIcon = (
//   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M7.66536 11.336L11.4181 5.836L3.91259 5.836L7.66536 11.336Z" fill="white" fill-opacity="0.6" />
//   </svg>
// );

export function AppHeaderLinks(props) {
  const { isLightTheme } = useThemeContext();
  const {
    small,
    openSettings,
    clickCloseIcon,
    redirectPopupTimestamp,
    showRedirectModal,
    account,
    library,
    usdcBalance,
    gusdBalance,
    savedSlippageAmount,
  } = props;
  const { chainId } = useChainId();
  const [isDepositModalVisible, setIsDepositModalVisible] = useState(false);

  const [earnDropdownExpanded, setEarnDropdownExpanded] = useState(false);

  const history = useHistory();

  const hash = window.location.hash;
  // const tradeBtnRef = useRef(null);
  // const moreBtnRef = useRef(null);
  // const analyticBtnRef = useRef(null);
  // const timeoutDuration = 0;
  // let timeout;
  // const onTradeMouseEnter = () => {
  //   clearTimeout(timeout);
  //   return tradeBtnRef.current?.click();
  // };

  // const onTradeMouseLeave = () => {
  //   timeout = setTimeout(() => tradeBtnRef.current?.click(), timeoutDuration);
  // };

  // const onMoreMouseEnter = () => {
  //   clearTimeout(timeout);
  //   return moreBtnRef.current?.click();
  // };

  // const onMoreMouseLeave = () => {
  //   timeout = setTimeout(() => moreBtnRef.current?.click(), timeoutDuration);
  // };

  // const onAnalyticMouseEnter = () => {
  //   clearTimeout(timeout);
  //   return analyticBtnRef.current?.click();
  // };

  // const onAnalyticMouseLeave = () => {
  //   timeout = setTimeout(() => analyticBtnRef.current?.click(), timeoutDuration);
  // };

  return (
    <div className="App-header-links">
      {small && (
        <div className="App-header-links-header">
          <a className="App-header-link-main" href="https://www.yummy.fi/">
            {/* <img src={isLightTheme ? logoImgV2Light : logoImg} alt="GMY Logo" /> */}
            <IconLogo />
          </a>
          <div
            className="App-header-menu-icon-block mobile-cross-menu"
            onClick={() => clickCloseIcon && clickCloseIcon()}
          >
            <FiX className="App-header-menu-icon" />
          </div>
        </div>
      )}

      {account && small && SUPPORTED_CHAIN_ID_DEPOSIT.includes(chainId) && (
        <>
          <TransactionModal
            isVisible={isDepositModalVisible}
            setIsVisible={setIsDepositModalVisible}
            library={library}
            chainId={chainId}
            savedSlippageAmount={savedSlippageAmount}
          ></TransactionModal>
          <div className="App-header-item-container">
            <div className="App-header-balance-container--mobile">
              <div className="App-header-mobile-left">
                <img src={exchangeIcon} alt="icon" style={{ marginRight: "4px" }} />
                <div className="App-header-usd-balance">${formatAmount(gusdBalance || 0, 30, 2, true)}</div>
                {/* <div className="App-header-eth-balance-container">
                  <span className="App-header-eth-icon">
                    <img src={importImage("ic_" + nativeTokenSymbol.toLowerCase() + "_24.svg")} alt="" />
                  </span>
                  <span className="App-header-eth-balance">{formatAmount(ethBalance, 18, 4, true)}</span>
                </div> */}
              </div>
              <div className="App-header-mobile-right">
                <button className="App-header-add-eth" onClick={() => setIsDepositModalVisible(true)}>
                  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.27344 3.04492V4.52832H0.451172V3.04492H7.27344ZM4.66211 0.255859V7.50195H3.06934V0.255859H4.66211Z"
                      fill={isLightTheme ? "#fff" : "#18191A"}
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="App-header-link-container">
        <HeaderLink
          to="/dashboard"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          className="gmy-item"
        >
          <IconDashboard />
          <Trans>Dashboard</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container">
        <HeaderLink
          to="/trade"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          className={cx("gmy-item", {
            active: ["#/trade", "#/v1"].includes(hash),
          })}
        >
          <IconPerp />
          <Trans>Perp</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container">
        <HeaderLink
          to={chainId === FANTOM ? "/swap" : "/spot-native"}
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          className={cx("gmy-item", {
            active: ["#/spot-native", "#/swap"].includes(hash),
          })}
        >
          <IconSwap />
          <Trans>Swap</Trans>
        </HeaderLink>
      </div>
      {false && small && (
        <div
          onClick={() => setEarnDropdownExpanded(!earnDropdownExpanded)}
          className={`App-header-link-container-menu ${earnDropdownExpanded ? "selected" : ""}`}
        >
          <div className="App-header-link-row">
            <span>Swap</span>

            <img src={isLightTheme ? arrowLight : arrow} alt="arrow" />
          </div>
          {earnDropdownExpanded && (
            <div className="App-header-link-list">
              <div className="App-header-link-container">
                <HeaderLink
                  to="/spot-native"
                  redirectPopupTimestamp={redirectPopupTimestamp}
                  showRedirectModal={showRedirectModal}
                >
                  <img src={isLightTheme ? perps_light : perps} alt="" />
                  <div className="wrap">
                    <span>Spot Native</span>
                    <span className="desc">Swap tokens in an instant.</span>
                  </div>
                </HeaderLink>
              </div>
              <div className="App-header-link-container">
                <HeaderLink
                  to={chainId === FANTOM ? "/swap" : "/spot-native"}
                  redirectPopupTimestamp={redirectPopupTimestamp}
                  showRedirectModal={showRedirectModal}
                >
                  <img src={isLightTheme ? swap_aggregator_ic_light : swap_aggregator_ic} alt="" />
                  <div className="wrap">
                    <span>Spot Through Aggregator</span>
                    <span className="desc">Swap tokens using the best route powered by MicroSwap</span>
                  </div>
                </HeaderLink>
              </div>
            </div>
          )}
        </div>
      )}

      {false && !small && (
        <div className="App-header-link-container">
          {/* <div className="new-dot" /> */}
          <div className="App-header-dropdown">
            <span
              className={cx({
                "menu-selected": ["#/spot-native", "#/swap"].includes(hash),
              })}
            >
              Swap <IconArrow class="arrow-icon-tab-header" />
              <div className="show-dropdown-link">
                <div className="menu-items">
                  {/* <div
                    className={cx("menu-item", {
                      actived: ["#/trade", "#/v1"].includes(hash),
                    })}
                  >
                    <HeaderLink
                      to="/trade"
                      redirectPopupTimestamp={redirectPopupTimestamp}
                      showRedirectModal={showRedirectModal}
                    >
                      <img src={isLightTheme ? perps_light : perps} alt="" />
                      <div className="wrap">
                        <span>Perp</span>
                        <span className="desc">Trade perpetual contracts</span>
                      </div>
                    </HeaderLink>
                  </div> */}
                  {/* <div
                    className={cx("menu-item", {
                      actived: ["#/spot-native"].includes(hash),
                    })}
                  >
                    <HeaderLink
                      to="/spot-native"
                      redirectPopupTimestamp={redirectPopupTimestamp}
                      showRedirectModal={showRedirectModal}
                    >
                      <img src={isLightTheme ? swap_ic_light : swap_ic} alt="" />
                      <div className="wrap">
                        <span>Spot Native</span>
                        <span className="desc">Swap tokens in an instant</span>
                      </div>
                    </HeaderLink>
                  </div> */}
                  {/* <div
                    className={cx("menu-item", {
                      actived: ["#/swap"].includes(hash),
                    })}
                  >
                    <HeaderLink
                      to="/swap"
                      redirectPopupTimestamp={redirectPopupTimestamp}
                      showRedirectModal={showRedirectModal}
                    >
                      <img src={isLightTheme ? swap_aggregator_ic_light : swap_aggregator_ic} alt="" />
                      <div className="wrap">
                        <span>Spot Through Aggregator</span>
                        <span className="desc">Swap tokens using the best route powered by MicroSwap</span>
                      </div>
                    </HeaderLink>
                  </div> */}
                </div>
              </div>
            </span>
          </div>
        </div>
      )}
      <div className="App-header-link-container disabled">
        <HeaderLink
          to="/earn/"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          className="gmy-item"
        >
          <IconEarn />
          <Trans>Earn</Trans>
        </HeaderLink>
        {/* <div className="gmy-item a disabled ">
          <IconEarn />
          <Trans>Earn</Trans>
        </div> */}
      </div>
      <div className="App-header-link-container ">
        <HeaderLink
          to="/portfolio"
          className="gmy-item"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <IconPor />
          <Trans>Portfolio</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container ">
        <HeaderLink
          to="/analytics"
          className="gmy-item"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <IconAnalytics />
          <Trans>Analytics</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container disabled">
        <HeaderLink
          to="/faucet/"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          className="gmy-item"
        >
          <IconFaucet />
          <Trans>Faucet</Trans>
        </HeaderLink>
        {/* <div className="gmy-item a disabled ">
          <IconEarn />
          <Trans>Earn</Trans>
        </div> */}
      </div>
      {/* <div className="App-header-link-container">
        <HeaderLink
          to="/vault"
          className="gmy-item"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <IconVaults />
          <Trans>Vaults</Trans>
        </HeaderLink>
      </div> */}
      {/* <div className="App-header-link-container">
        <HeaderLink
          to="/faucet"
          className="gmy-item"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <IconFaucet />
          <Trans>Faucet</Trans>
        </HeaderLink>
      </div> */}
      {/* <div className="App-header-link-container">
        <HeaderLink
          to="/vault"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <span>Vault</span>
        </HeaderLink>
      </div> */}
      {/* <div className="App-header-link-container">
        <HeaderLink
          className={cx("clickable", {
            active: ["/buy", "/buy_gmy", "/buy_glp", "/buy/mint"].includes(
              window.location.hash.slice(1, window.location.hash.length)
            ),
          })}
          to="/buy"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <Trans>Buy</Trans>
        </HeaderLink>
      </div> */}

      {/* {Date.now() < 1692180000000 && (
        <div className="App-header-link-container">
          <HeaderLink
            to="/migrate"
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
          >
            <Trans>Migrate</Trans>
          </HeaderLink>
        </div>
      )}
      <div className="App-header-link-container">
        <ExternalLink href="https://prediction.yummy.fi/">
          <Trans>Prediction (BETA)</Trans>
        </ExternalLink>
      </div>
      {chainId !== ARBITRUM && (
        <div className="App-header-link-container">
          <HeaderLink to="/nft" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
            <Trans>NFT Club</Trans>
          </HeaderLink>
        </div>
      )} */}

      {/* {chainId === FANTOM && (
        <div className="App-header-link-container">
          <HeaderLink
            to="/portfolio"
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
          >
            <Trans>Portfolio</Trans>
          </HeaderLink>
        </div>
      )} */}

      {/* {small && (
        <div
          onClick={() => setBridgeDropdownExpanded(!bridgeDropdownExpanded)}
          className={`App-header-link-container-menu ${bridgeDropdownExpanded ? "selected" : ""}`}
        >
          <div className="App-header-link-row">
            <span>Bridge</span>

            <img src={isLightTheme ? arrowLight : arrow} alt="arrow" />
          </div>
          {bridgeDropdownExpanded && (
            <div className="App-header-link-list">
              <div className="App-header-link-container">
                <HeaderLink
                  to="/bridge"
                  redirectPopupTimestamp={redirectPopupTimestamp}
                  showRedirectModal={showRedirectModal}
                >
                  <img src={isLightTheme ? bridge_light : bridge_light} alt="" />
                  <div className="wrap">
                    <span>LayerZero</span>
                    <span className="desc">Bridge GMY seamlessly via Layer0 technology</span>
                  </div>
                </HeaderLink>
              </div>
              <div className="App-header-link-container">
                <a href="/#/bridge-wormhole" target="_blank">
                  <img src={isLightTheme ? bridge_wormhole_light : bridge_wormhole_light} alt="" />
                  <div className="wrap">
                    <span>Wormhole</span>
                    <span className="desc">Bridge tokens seamlessly via Wormhole technology</span>
                  </div>
                </a>
              </div>
            </div>
          )}
        </div>
      )}

      {small && (
        <div
          onClick={() => setAnalyticDropdownExpanded(!analyticDropdownExpanded)}
          className={`App-header-link-container-menu ${analyticDropdownExpanded ? "selected" : ""}`}
        >
          <div className="App-header-link-row">
            <span>Analytics</span>
            <img src={isLightTheme ? arrowLight : arrow} alt="arrow" />
          </div>
          {analyticDropdownExpanded && (
            <div className="App-header-link-list">
              {!isLanding && (
                <>
                  {" "}
                  <div className="App-header-link-container">
                    <HeaderLink
                      to="/analytic/overview"
                      redirectPopupTimestamp={redirectPopupTimestamp}
                      showRedirectModal={showRedirectModal}
                    >
                      <img src={isLightTheme ? analytic_light : analytic} alt="" />
                      <div className="wrap">
                        <span>Analytics V1</span>
                        <span className="desc">The comprehensive statistics and data visualization of V1</span>
                      </div>
                    </HeaderLink>
                  </div>
                  <div className="App-header-link-container">
                    <HeaderLink
                      to="/analytic-v2/overview"
                      redirectPopupTimestamp={redirectPopupTimestamp}
                      showRedirectModal={showRedirectModal}
                    >
                      <img src={isLightTheme ? analytic_v2_light : analytic_v2} alt="" />
                      <div className="wrap">
                        <span>
                          Analytics <span className="tag-v2">V2</span>
                        </span>
                        <span className="desc">The comprehensive statistics and data visualization of V2</span>
                      </div>
                    </HeaderLink>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )} */}

      {/* {small && (
        <div
          onClick={() => setMoreDropdownExpanded(!moreDropdownExpanded)}
          className={`App-header-link-container-menu ${moreDropdownExpanded ? "selected" : ""}`}
        >
          <div className="App-header-link-row">
            <span>More</span>
            <img src={isLightTheme ? arrowLight : arrow} alt="arrow" />
          </div>
          {moreDropdownExpanded && (
            <div className="App-header-link-list">
              {!isLanding && (
                <>
                  {" "}
                  <div className="App-header-link-container">
                    <HeaderLink
                      to="/referrals"
                      redirectPopupTimestamp={redirectPopupTimestamp}
                      showRedirectModal={showRedirectModal}
                    >
                      <img src={isLightTheme ? referral_light : referral} alt="" />
                      <div className="wrap">
                        <span>Referrals</span>
                        <span className="desc">Earn great rebates by referring your friends for trading</span>
                      </div>
                    </HeaderLink>
                  </div>
                  <div className="App-header-link-container">
                    <HeaderLink
                      to="/bridge"
                      redirectPopupTimestamp={redirectPopupTimestamp}
                      showRedirectModal={showRedirectModal}
                    >
                      <img src={isLightTheme ? bridge_ic_light : bridge_ic} alt="" />
                      <div className="wrap">
                        <Trans>Bridge</Trans>
                        <span className="desc">Bridge GMY seamlessly via Layer0 technology</span>
                      </div>
                    </HeaderLink>
                  </div>
                  <div className="App-header-link-container">
                    <HeaderLink
                      to="/news"
                      redirectPopupTimestamp={redirectPopupTimestamp}
                      showRedirectModal={showRedirectModal}
                    >
                      <img src={isLightTheme ? news_light : news} alt="" />
                      <div className="wrap">
                        <span>News</span>
                        <span className="desc">Stay updated with the latest crypto news</span>
                      </div>
                    </HeaderLink>
                  </div>
                  <div className="App-header-link-container">
                    <ExternalLink href="https://docs.yummy.fi">
                      <img src={isLightTheme ? about_light : about} alt="" />
                      <div className="wrap">
                        <span>About</span>
                        <span className="desc">Yummy Exchange Documentation</span>
                      </div>
                    </ExternalLink>
                  </div>
                </>
              )}

              <div className="App-header-link-container">
                <ExternalLink href="https://skeleton.finance">
                  <img src={isLightTheme ? skeleton_ic_light : skeleton_ic} alt="" />
                  <div className="wrap">
                    <span>Skeleton</span>
                    <span className="desc">V3 DEX powered by Yummy</span>
                  </div>
                </ExternalLink>
              </div>

              {!isLanding && chainId === FANTOM && (
                <div className="App-header-link-container">
                  <HeaderLink
                    to="/proof-of-locked-liquidity"
                    redirectPopupTimestamp={redirectPopupTimestamp}
                    showRedirectModal={showRedirectModal}
                  >
                    <img src={isLightTheme ? poll_light : poll} alt="" />
                    <div className="wrap">
                      <span>PoL</span>
                      <span className="desc">Protocol-Owned GMY Liquidity</span>
                    </div>
                  </HeaderLink>
                </div>
              )}
            </div>
          )}
        </div>
      )} */}

      {/* {!small && (
        <div className="App-header-link-container">

          <div className="App-header-dropdown">
            <span
              className={`${
                [
                  "#/referrals",
                  "#/news",
                  "#/proof-of-locked-liquidity",
                  "#/analytic/overview",
                  "#/analytic/top-traders",
                  "#/analytic/top-positions",
                  "#/analytic/top-referrals",
                  "#/analytic/oracle",
                  "#/analytic/search",
                  "#/analytic-v2/overview",
                  "#/analytic-v2/top-traders",
                  "#/analytic-v2/top-positions",
                  "#/analytic-v2/contest",
                  "#/analytic-v2/search",
                  "#/bridge",
                  "#/bridge-wormhole",
                ].includes(hash)
                  ? "menu-selected"
                  : "more-gmy"
              }`}
            >
              <IconArrow class="arrow-icon-tab-header" /> More
              <div className="show-dropdown-link">
                <div className="menu-items">
                  <div
                    className={cx("menu-item", {
                      actived: ["#/referrals"].includes(hash),
                    })}
                  >
                    <HeaderLink
                      to="/referrals"
                      redirectPopupTimestamp={redirectPopupTimestamp}
                      showRedirectModal={showRedirectModal}
                    >
                      <img src={isLightTheme ? referral_light : referral} alt="" />
                      <div className="wrap">
                        <span>Referrals</span>
                        <span className="desc">Earn great rebates by referring your friends for trading</span>
                      </div>
                    </HeaderLink>
                  </div>
                  <div
                    className={cx("menu-item", {
                      actived: [
                        "#/analytic/overview",
                        "#/analytic/top-traders",
                        "#/analytic/top-positions",
                        "#/analytic/top-referrals",
                        "#/analytic/oracle",
                        "#/analytic/search",
                      ].includes(hash),
                    })}
                  >
                    <HeaderLink
                      to="/analytic/overview"
                      redirectPopupTimestamp={redirectPopupTimestamp}
                      showRedirectModal={showRedirectModal}
                    >
                      <img src={isLightTheme ? analytic_light : analytic} alt="" />
                      <div className="wrap">
                        <span>Analytics V1</span>
                        <span className="desc">The comprehensive statistics and data visualization of V1</span>
                      </div>
                    </HeaderLink>
                  </div>
                  <div
                    className={cx("menu-item", {
                      actived: [
                        "#/analytic-v2/overview",
                        "#/analytic-v2/top-traders",
                        "#/analytic-v2/top-positions",
                        "#/analytic-v2/contest",
                        "#/analytic-v2/search",
                      ].includes(hash),
                    })}
                  >
                    <HeaderLink
                      to="/analytic-v2/overview"
                      redirectPopupTimestamp={redirectPopupTimestamp}
                      showRedirectModal={showRedirectModal}
                    >
                      <img src={isLightTheme ? analytic_v2_light : analytic_v2} alt="" />
                      <div className="wrap">
                        <span className="inner">
                          Analytics <span className="tag-v2">V2</span>{" "}
                        </span>
                        <span className="desc">The comprehensive statistics and data visualization of V2</span>
                      </div>
                    </HeaderLink>
                  </div>
                  <div
                    className={cx("menu-item", {
                      actived: ["#/bridge"].includes(hash),
                    })}
                  >
                    <HeaderLink
                      to="/bridge"
                      redirectPopupTimestamp={redirectPopupTimestamp}
                      showRedirectModal={showRedirectModal}
                    >
                      <img src={isLightTheme ? bridge_light : bridge_light} alt="" />
                      <div className="wrap">
                        <span>LayerZero Bridge</span>
                        <span className="desc">Bridge GMY seamlessly via Layer0 technology</span>
                      </div>
                    </HeaderLink>
                  </div>
                  <div
                    className={cx("menu-item", {
                      actived: ["#/bridge-wormhole"].includes(hash),
                    })}
                  >
                    <ExternalLink href="/#/bridge-wormhole" target="_blank">
                      <img src={isLightTheme ? bridge_wormhole_light : bridge_wormhole_light} alt="" />
                      <div className="wrap">
                        <span>Wormhole Bridge</span>
                        <span className="desc">Bridge tokens seamlessly via Wormhole technology</span>
                      </div>
                    </ExternalLink>
                  </div>

                  <div
                    className={cx("menu-item", {
                      actived: ["#/news"].includes(hash),
                    })}
                  >
                    <HeaderLink
                      to="/news"
                      redirectPopupTimestamp={redirectPopupTimestamp}
                      showRedirectModal={showRedirectModal}
                    >
                      <img src={isLightTheme ? news_light : news} alt="" />
                      <div className="wrap">
                        <span>News</span>
                        <span className="desc">Stay updated with the latest crypto news</span>
                      </div>
                    </HeaderLink>
                  </div>
                  {chainId === FANTOM && (
                    <div
                      className={cx("menu-item", {
                        actived: ["#/proof-of-locked-liquidity"].includes(hash),
                      })}
                    >
                      <HeaderLink
                        to="/proof-of-locked-liquidity"
                        redirectPopupTimestamp={redirectPopupTimestamp}
                        showRedirectModal={showRedirectModal}
                      >
                        <img src={isLightTheme ? poll_light : poll} alt="" />
                        <div className="wrap">
                          <span>PoL</span>
                          <span className="desc">Protocol-Owned GMY Liquidity</span>
                        </div>
                      </HeaderLink>
                    </div>
                  )}
                  <div className="menu-item">
                    <ExternalLink href="https://docs.yummy.fi">
                      <img src={isLightTheme ? about_light : about} alt="" />
                      <div className="wrap">
                        <span>About</span>
                        <span className="desc">Yummy Exchange Documentation</span>
                      </div>
                    </ExternalLink>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
      )} */}
    </div>
  );
}
