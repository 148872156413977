import Footer from "components/Footer/Footer";
import "swiper/swiper.min.css";
import "./Home.css";
import "./Home.scss";
// import "swiper/modules/navigation/navigation.scss"; // Navigation module
// import "swiper/modules/pagination/pagination.scss"; // Pagination module
// import "swiper/swiper.scss";
// import "swiper/modules/effect-fade/effect-fade.scss";

import costIcon from "img/ic_cost.svg";
import liquidityIcon from "img/ic_liquidity.svg";
import simpleSwapIcon from "img/ic_simpleswaps.svg";
import totaluserIcon from "img/ic_totaluser.svg";

import frame_sticker from "img/frame_sticker.svg";
import frame_trading from "img/frame_trading.svg";
import statsIcon from "img/ic_stats.svg";
import tradingIcon from "img/ic_trading.svg";

import useSWR from "swr";

import { ACTIVE_CHAIN_IDS, arrayURLFetcher, getStatsInfo } from "lib/legacy";

import { useChainId } from "lib/chains";

import TokenCard from "components/TokenCard/TokenCard";
import { getServerUrl } from "config/backend";
import { formatAmount } from "lib/numbers";

import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

export default function Home({ showRedirectModal, redirectPopupTimestamp }) {
  SwiperCore.use([Autoplay, Navigation, Pagination, EffectFade]);

  const history = useHistory();

  const { chainId } = useChainId();
  // const [openedFAQIndex, setOpenedFAQIndex] = useState(null)
  // const faqContent = [{
  //   id: 1,
  //   question: "What is GMY?",
  //   answer: "GMY is a decentralized spot and perpetual exchange that supports low swap fees and zero price impact trades.<br><br>Trading is supported by a unique multi-asset pool that earns liquidity providers fees from market making, swap fees, leverage trading (spreads, funding fees & liquidations), and asset rebalancing.<br><br>Dynamic pricing is supported by Chainlink Oracles along with TWAP pricing from leading volume DEXs."
  // }, {
  //   id: 2,
  //   question: "What is the GMY Governance Token? ",
  //   answer: "The GMY token is the governance token of the GMY ecosystem, it provides the token owner voting rights on the direction of the GMY platform.<br><br>Additionally, when GMY is staked you will earn 30% of the platform-generated fees, you will also earn Escrowed GMY tokens and Multiplier Points."
  // }, {
  //   id: 3,
  //   question: "What is the GLP Token? ",
  //   answer: "The GLP token represents the liquidity users provide to the GMY platform for Swaps and Margin Trading.<br><br>To provide liquidity to GLP you <a href='https://gmy.io/buy_glp' target='_blank'>trade</a> your crypto asset BTC, ETH, LINK, UNI, USDC, USDT, MIM, or FRAX to the liquidity pool, in exchange, you gain exposure to a diversified index of tokens while earning 50% of the platform trading fees and esGMY."
  // }, {
  //   id: 4,
  //   question: "What can I Trade on GMY? ",
  //   answer: "On GMY you can swap or margin trade any of the following assets: ETH, BTC, LINK, UNI, USDC, USDT, MIM, FRAX, with others to be added. "
  // }]

  // const toggleFAQContent = function(index) {
  //   if (openedFAQIndex === index) {
  //     setOpenedFAQIndex(null)
  //   } else {
  //     setOpenedFAQIndex(index)
  //   }
  // }
  // const { data: dataStats } = useSWR(getServerUrl(chainId, "/app-stats"), {
  //   fetcher: arrayURLFetcher,
  //   refreshInterval: 10000,
  // });

  const { data: dataStatsAll } = useSWR(
    ACTIVE_CHAIN_IDS.map((chainId) => getServerUrl(chainId, "/app-stats")),
    {
      fetcher: arrayURLFetcher,
      refreshInterval: 10000,
    }
  );

  const dataStats = getStatsInfo(dataStatsAll);
  // ARBITRUM

  // const arbitrumPositionStatsUrl = getServerUrl(ARBITRUM, "/position_stats");
  // const { data: arbitrumPositionStats } = useSWR([arbitrumPositionStatsUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.json()),
  // });

  // const arbitrumTotalVolumeUrl = getServerUrl(ARBITRUM, "/total_volume");
  // const { data: arbitrumTotalVolume } = useSWR([arbitrumTotalVolumeUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.json()),
  // });

  // AVALANCHE

  // const avalanchePositionStatsUrl = getServerUrl(AVALANCHE, "/position_stats");
  // const { data: avalanchePositionStats } = useSWR([avalanchePositionStatsUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.json()),
  // });

  // const avalancheTotalVolumeUrl = getServerUrl(AVALANCHE, "/total_volume");
  // const { data: avalancheTotalVolume } = useSWR([avalancheTotalVolumeUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.json()),
  // });

  // Total Volume

  // const arbitrumTotalVolumeSum = getTotalVolumeSum(arbitrumTotalVolume);
  // const avalancheTotalVolumeSum = getTotalVolumeSum(avalancheTotalVolume);

  // let totalVolumeSum = bigNumberify(0);
  // if (arbitrumTotalVolumeSum && avalancheTotalVolumeSum) {
  //   totalVolumeSum = totalVolumeSum.add(arbitrumTotalVolumeSum);
  //   totalVolumeSum = totalVolumeSum.add(avalancheTotalVolumeSum);
  // }

  // Open Interest

  // let openInterest = bigNumberify(0);
  // if (
  //   arbitrumPositionStats &&
  //   arbitrumPositionStats.totalLongPositionSizes &&
  //   arbitrumPositionStats.totalShortPositionSizes
  // ) {
  //   openInterest = openInterest.add(arbitrumPositionStats.totalLongPositionSizes);
  //   openInterest = openInterest.add(arbitrumPositionStats.totalShortPositionSizes);
  // }

  // if (
  //   avalanchePositionStats &&
  //   avalanchePositionStats.totalLongPositionSizes &&
  //   avalanchePositionStats.totalShortPositionSizes
  // ) {
  //   openInterest = openInterest.add(avalanchePositionStats.totalLongPositionSizes);
  //   openInterest = openInterest.add(avalanchePositionStats.totalShortPositionSizes);
  // }

  // user stat
  // const arbitrumUserStats = useUserStat(ARBITRUM);
  // const avalancheUserStats = useUserStat(AVALANCHE);
  // let totalUsers = 0;

  // if (arbitrumUserStats && arbitrumUserStats.uniqueCount) {
  //   totalUsers += arbitrumUserStats.uniqueCount;
  // }

  // if (avalancheUserStats && avalancheUserStats.uniqueCount) {
  //   totalUsers += avalancheUserStats.uniqueCount;
  // }

  const LaunchExchangeButton = () => {
    return (
      // <HeaderLink
      //   className="default-btn"
      //   to="/trade"
      //   redirectPopupTimestamp={redirectPopupTimestamp}
      //   showRedirectModal={showRedirectModal}
      // >
      //   <span>Launch App</span>
      // </HeaderLink>
      <a className="default-btn" href="https://app.yummy.fi/#/trade">
        <span>Launch App</span>
      </a>
    );
  };

  const isMobileScreen = useMediaQuery({ query: "(max-width: 600px)" });

  return (
    <div className="Home">
      <div className="Home-top">
        <div className="wrapper-slick">
          <Swiper
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            speed={2500}
            loop
            effect={"fade"}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Navigation, Pagination, EffectFade]}
          >
            <SwiperSlide>
              <div className="child referrals">
                <div className="title">Yummy Exchange</div>
                {isMobileScreen ? (
                  <div className="content">
                    <div>Trading Competition</div>
                    <div>
                      prize pool <span>$10,000</span> in FTM
                    </div>
                  </div>
                ) : (
                  <div className="content">
                    Trading Competition prize pool <span>$10,000</span> in USDC
                  </div>
                )}
                <a href="https://app.yummy.fi/#/trade" target="_blank" rel="noreferrer">
                  <div className="action">Trade now</div>
                </a>

                <img className="frame" src={frame_trading} alt="frame_trading" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="child sticker">
                <div className="title">Yummy Exchange</div>

                {isMobileScreen ? (
                  <div className="content">
                    <div>Expansion to Optimism</div>
                  </div>
                ) : (
                  <div className="content">Expansion to Optimism</div>
                )}

                <a href="https://app.yummy.fi/#/trade" target="_blank" rel="noreferrer">
                  <div className="action">Trade now</div>
                </a>

                <img className="frame" src={frame_sticker} alt="frame_sticker" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="Home-title-section-container default-container">
          <div className="Home-title-section">
            <div className="Home-title">
              <span>
                Decentralized
                <br />
                Perpetual Exchange
              </span>
            </div>
            <div className="Home-description">
              <span>
                Trade BTC, ETH, FTM and other top cryptocurrencies with up to 100x leverage directly from your wallet
              </span>
            </div>
            <LaunchExchangeButton />
          </div>
        </div>
        <div className="Home-latest-info-container default-container">
          <div className="Home-latest-info-block">
            <img src={tradingIcon} alt="Total Trading Volume Icon" className="Home-latest-info__icon" />
            <div className="Home-latest-info-content">
              <div className="Home-latest-info__title">
                <span>Total Trading Volume</span>
              </div>
              {/* <div className="Home-latest-info__value">${formatAmount(totalVolumeSum, USD_DECIMALS, 0, true)}</div> */}
              <div className="Home-latest-info__value">
                ${dataStats?.totalVolume ? formatAmount(dataStats?.totalVolume, 30, 0, true) : "--"}
              </div>
            </div>
          </div>
          <div className="Home-latest-info-block">
            <img src={statsIcon} alt="Open Interest Icon" className="Home-latest-info__icon" />
            <div className="Home-latest-info-content">
              <div className="Home-latest-info__title">
                <span>Open Interest</span>
              </div>
              {/* <div className="Home-latest-info__value">${formatAmount(openInterest, USD_DECIMALS, 0, true)}</div> */}
              <div className="Home-latest-info__value">
                ${dataStats?.openInterest ? formatAmount(dataStats?.openInterest, 30, 0, true) : "--"}
              </div>
            </div>
          </div>
          <div className="Home-latest-info-block">
            <img src={totaluserIcon} alt="Total Users Icon" className="Home-latest-info__icon" />
            <div className="Home-latest-info-content">
              <div className="Home-latest-info__title">
                <span>Total Users</span>
              </div>
              {/* <div className="Home-latest-info__value">{numberWithCommas(totalUsers.toFixed(0))}</div> */}
              <div className="Home-latest-info__value">{formatAmount(dataStats?.totalUser || 0, 0, 0, true)}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="Home-benefits-section">
        <div className="Home-benefits default-container">
          <div className="Home-benefit">
            <div className="Home-benefit-icon">
              <img src={liquidityIcon} alt="Reduce Liquidation Risks Icon" className="Home-benefit-icon-symbol" />
              <div className="Home-benefit-title">
                <span>Reduce Liquidation Risks</span>
              </div>
            </div>
            <div className="Home-benefit-description">
              <span>
                An aggregate of high-quality price feeds determine when liquidations occur. This keeps positions safe
                from temporary wicks.
              </span>
            </div>
          </div>
          <div className="Home-benefit">
            <div className="Home-benefit-icon">
              <img src={costIcon} alt="Save on Costs Icon" className="Home-benefit-icon-symbol" />
              <div className="Home-benefit-title">
                <span>Save on Costs</span>
              </div>
            </div>
            <div className="Home-benefit-description">
              <span>
                Enter and exit positions with minimal spread and zero price impact. Get the optimal price without
                incurring additional costs.
              </span>
            </div>
          </div>
          <div className="Home-benefit">
            <div className="Home-benefit-icon">
              <img src={simpleSwapIcon} alt="Simple Swaps Icon" className="Home-benefit-icon-symbol" />
              <div className="Home-benefit-title">
                <span>Simple Swaps</span>
              </div>
            </div>
            <div className="Home-benefit-description">
              <span>
                Open positions through a simple swap interface. Conveniently swap from any supported asset into the
                position of your choice.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="Home-token-card-section">
        <div className="Home-token-card-container default-container">
          <div className="Home-token-card-info">
            <div className="Home-token-card-info__title">
              <span>Ecosystem tokens</span>
            </div>
          </div>
          <TokenCard showRedirectModal={showRedirectModal} redirectPopupTimestamp={redirectPopupTimestamp} />
        </div>
      </div>

      {/* <div className="Home-video-section">
        <div className="Home-video-container default-container">
          <div className="Home-video-block">
            <img src={gmyBigIcon} alt="gmybig" />
          </div>
        </div>
      </div> */}
      {/* <div className="Home-faqs-section">
        <div className="Home-faqs-container default-container">
          <div className="Home-faqs-introduction">
            <div className="Home-faqs-introduction__title">FAQs</div>
            <div className="Home-faqs-introduction__description">Most asked questions. If you wish to learn more, please head to our Documentation page.</div>
            <a href="https://docs.yummy.fi/" className="default-btn Home-faqs-documentation">Documentation</a>
          </div>
          <div className="Home-faqs-content-block">
            {
              faqContent.map((content, index) => (
                <div className="Home-faqs-content" key={index} onClick={() => toggleFAQContent(index)}>
                  <div className="Home-faqs-content-header">
                    <div className="Home-faqs-content-header__icon">
                      {
                        openedFAQIndex === index ? <FiMinus className="opened" /> : <FiPlus className="closed" />
                      }
                    </div>
                    <div className="Home-faqs-content-header__text">
                      { content.question }
                    </div>
                  </div>
                  <div className={ openedFAQIndex === index ? "Home-faqs-content-main opened" : "Home-faqs-content-main" }>
                    <div className="Home-faqs-content-main__text">
                      <div dangerouslySetInnerHTML={{__html: content.answer}} >
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div> */}
      <Footer showRedirectModal={showRedirectModal} redirectPopupTimestamp={redirectPopupTimestamp} />
    </div>
  );
}
